import React from 'react'
import { StrapiGlobal } from '../types/StrapiGlobal'
import StrapiSeo from '../types/StrapiSeo'
import Footer from './global/Footer'
import Navbar from './global/Navbar'
import PreviewBanner from './global/PreviewBanner'
import Seo from './seo'

interface LayoutProps {
  children: React.ReactNode
  global: StrapiGlobal
  preview: boolean
  seo: StrapiSeo
  locale: string
}

const Layout = ({ children, global, preview, seo, locale }: LayoutProps) => (
  <div className="layout">
    <Seo seo={seo} />
    {preview && <PreviewBanner />}
    <Navbar
      navBar={global.navbar}
      locale={locale}
      mainMenuItems={global.MainMenuItems}
    />
    <div style={{ flexDirection: 'column', minHeight: 'calc(100vh - 300px)' }}>
      {children}
    </div>
    <Footer footer={global.footer} logo={global?.navbar?.logo} />
  </div>
)

export default Layout
