import Link from 'next/link'
import { StrapiMenuItem } from '../../../types/StrapiGlobal'

interface NavProps {
  links: StrapiMenuItem[]
  locale: string
}

const Nav = ({ links, locale }: NavProps) => (
  <div className="navBar__navContainer">
    <input
      className="side-menu"
      type="checkbox"
      id="side-menu"
      aria-label="Menu button"
    />
    <label className="hamb" htmlFor="side-menu">
      <span className="hamb-line"></span>
    </label>
    <nav>
      {links.map((link, index) => (
        <>
          <div className="navbar__link">
            <Link
              href={`${link.link}?lang=${locale || 'en'}`}
              key={`navigationLink-${index}`}
              onClick={() => {
                const sideMenu = document.getElementById('side-menu')
                if (sideMenu) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  sideMenu.checked = false
                }
              }}
            >
              {link.label}
            </Link>
            {link.children && link.children.length > 0 && (
              <div className="navbar__childMenu">
                {link.children.map((child, childIndex) => (
                  <Link
                    href={`${child.link}`}
                    key={`navigationLink-${childIndex}`}
                    className="navbar__childLink"
                    onClick={() => {
                      const sideMenu = document.getElementById('side-menu')
                      if (sideMenu) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sideMenu.checked = false
                      }
                    }}
                  >
                    {child.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </>
      ))}
    </nav>
  </div>
)

export default Nav
