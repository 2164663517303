export function getStrapiMedia(url: string) {
  if (url == null) {
    return null
  }
  if (url.startsWith('http') || url.startsWith('//')) {
    return url
  }
  return `${process.env.NEXT_PUBLIC_API_URL || '/cms'}${url}`
}

export function getStrapiURL(path: string) {
  return `${process.env.NEXT_PUBLIC_API_URL || '/cms'}/api${path}`
}

export function handleRedirection(preview?: boolean, custom?: string | null) {
  if (preview) {
    return {
      redirect: {
        destination: '/api/exit-preview',
        permanent: false,
      },
    }
  }
  if (custom) {
    return {
      redirect: {
        destination: `/${custom}`,
        permanent: false,
      },
    }
  }
  return {
    redirect: {
      destination: '/404',
      permanent: false,
    },
  }
}

// export function getData(slug, locale, apiID, kind, preview) {
//   const previewParams = preview
//     ? '&publicationState=preview&published_at_null=true'
//     : '';

//   if (kind == 'collectionType') {
//     let prefix = `/${pluralize(apiID)}`;
//     if (apiID == 'page') {
//       prefix = ``;
//     } else if (apiID == 'article') {
//       prefix = `/blog`;
//     }
//     const slugToReturn = `${prefix}/${slug}?lang=${locale}`;
//     const apiUrl = `/${pluralize(
//       apiID
//     )}?filters[slug][$eq]=${slug}&locale=${locale}${previewParams}&populate[blocks][populate]=members.picture,header,buttons.link,faq,featuresCheck,cards,pricingCards.perks,articles,restaurants,author.picture,images,cards.image,image&populate=localizations&populate[seo][populate]=metaSocial.image`;

//     return {
//       data: getStrapiURL(apiUrl),
//       slug: slugToReturn,
//     };
//   } else {
//     const apiUrl = `/${apiID}?locale=${locale}${previewParams}&populate[blocks][populate]=*,buttons.link&populate=localizations&populate[header]=*&populate[seo]=metaSocial`;

//     if (apiID.includes('-page')) {
//       const slugToReturn =
//         apiID == 'blog-page'
//           ? `/${apiID.replace('-page', '')}?lang=${locale}`
//           : `/${apiID.replace('-page', 's')}?lang=${locale}`;
//       return {
//         data: getStrapiURL(apiUrl),
//         slug: slugToReturn,
//       };
//     } else {
//       return {
//         data: getStrapiURL(apiUrl),
//         slug: `/${apiID}?lang=${locale}`,
//       };
//     }
//   }
// }

// export async function getRestaurants(key) {
//   const categoryName = key.queryKey[1].category;
//   const placeName = key.queryKey[2].place;
//   const localeCode = key.queryKey[3].locale;
//   const pageNumber = key.queryKey[4].page;
//   const perPage = key.queryKey[5].perPage;
//   const start = +pageNumber === 1 ? 0 : (+pageNumber - 1) * perPage;

//   let baseUrl = getStrapiURL(
//     `/restaurants?pagination[limit]=${perPage}&pagination[start]=${start}&pagination[withCount]=true&populate=images,category,place,information,seo`
//   );

//   if (categoryName) {
//     baseUrl = `${baseUrl}&filters[category][name][$eq]=${categoryName}`;
//   }

//   if (placeName) {
//     baseUrl = `${baseUrl}&filters[place][name][$eq]=${placeName}`;
//   }

//   if (localeCode) {
//     baseUrl = `${baseUrl}&locale=${localeCode}`;
//   }

//   const res = await fetch(baseUrl);
//   const restaurants = await res.json();

//   return {
//     restaurants: restaurants.data,
//     count: restaurants.meta.pagination.total,
//   };
// }

// export async function getArticles(key) {
//   const categoryName = key.queryKey[1].category;
//   const localeCode = key.queryKey[2].locale;
//   const pageNumber = key.queryKey[3].page;
//   const perPage = key.queryKey[4].perPage;

//   const start = +pageNumber === 1 ? 0 : (+pageNumber - 1) * perPage;

//   let baseUrl = getStrapiURL(
//     `/articles?pagination[limit]=${perPage}&pagination[start]=${start}&pagination[withCount]=true&populate=image,category,author,seo`
//   );

//   if (categoryName) {
//     baseUrl = `${baseUrl}&filters[category][name][$eq]=${categoryName}`;
//   }

//   if (localeCode) {
//     baseUrl = `${baseUrl}&locale=${localeCode}`;
//   }

//   const res = await fetch(baseUrl);
//   const articles = await res.json();

//   return { articles: articles.data, count: articles.meta.pagination.total };
// }
