import Link from 'next/link'
import { StrapiFooter } from '../../../types/StrapiGlobal'
import StrapiImage from '../../../types/StrapiImage'
import { getStrapiMedia } from '../../../utils'
import PageWidth from '../../PageWidth'
import Logo from '../Navbar/logo'

interface FooterProps {
  footer: StrapiFooter
  logo: StrapiImage
}

const Footer = ({ footer, logo }: FooterProps) => {
  const locale = ''

  return (
    <footer>
      <div
        className="footer__image"
        style={{
          backgroundImage: `url("${getStrapiMedia(
            footer?.backgroundImage?.data?.attributes?.formats?.medium?.url ||
              footer?.backgroundImage?.data?.attributes?.url
          )}")`,
        }}
      >
        <div className="footer__imageOverlay"></div>
        <PageWidth className="footer" style={{ zIndex: 2 }}>
          <div className="footer__columns">
            <div className="footer__column">
              <Logo logo={logo} locale="se"></Logo>
            </div>
            <div className="footer__column">
              <p>{footer.adress}</p>
              <a className="footer__email" href={`mailto:${footer.email}`}>
                {footer.email}
              </a>
            </div>
            <div className="footer__column">
              {footer.links.map((link, index) => (
                <Link
                  href={`${link.link}?lang=${locale || 'en'}`}
                  key={`navigationLink-${index}`}
                  className="footer__link"
                >
                  {link.label}
                </Link>
              ))}
            </div>
            <div className="footer__column">
              <p className="footer__aboutTitle">{footer.aboutTitle}</p>
              <p>{footer.aboutText}</p>
            </div>
          </div>
          <div className="footer__divider"></div>
          <div className="footer__ccColumns">
            <p className="footer__ccText">© 2023 Enklakassan in Sweden AB</p>
            <p className="footer__ccText">All rights reserved</p>
          </div>
        </PageWidth>
      </div>
    </footer>
  )
}

Footer.defaultProps = {}

export default Footer
