import React from 'react'

interface PageWidthProps {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

const PageWidth = ({ children, style, className }: PageWidthProps) => (
  <div className={`pageWidth ${className}`} style={style}>
    {children}
  </div>
)

export default PageWidth
