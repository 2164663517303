import { StrapiMenuItem, StrapiNavbar } from '../../../types/StrapiGlobal'
import Logo from './logo'
import Nav from './nav'

interface NavigationProps {
  locale: string
  navBar: StrapiNavbar
  mainMenuItems: StrapiMenuItem[]
}

const Navigation = ({ locale, navBar, mainMenuItems }: NavigationProps) => (
  <>
    <header className="navbar">
      <Logo locale={locale} logo={navBar?.logo} />

      <Nav links={mainMenuItems} locale={locale} />
    </header>
    <div className="navbarContainer"></div>
  </>
)

Navigation.defaultProps = {}

export default Navigation
