import Link from 'next/link'
import Image from 'next/image'
import { getStrapiMedia } from '../../../utils'

interface LogoProps {
  locale: string
  logo: {
    data: {
      attributes: {
        url: string
        alternativeText: string
        width: number
        height: number
      }
    }
  }
}

const Logo = ({ locale, logo }: LogoProps) => (
  <Link href={`/?lang=${locale || 'en'}`}>
    <Image
      loader={() => getStrapiMedia(logo?.data?.attributes?.url) || ''}
      src={getStrapiMedia(logo?.data?.attributes?.url) || ''}
      alt={logo?.data?.attributes?.alternativeText || ''}
      className="logo"
      width={logo?.data?.attributes?.width}
      height={logo?.data?.attributes?.height}
    />
  </Link>
)

export default Logo
